import React from 'react'
import './experience.css'
import {BsFillCheckCircleFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='skills'>
      {/* <h5>Technical</h5> */}
      <h2>Technical Skills</h2>

      <div className="container experience__container">
        <div className="experience__backend">
        <h3>Backend</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>C++</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>Java</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>SQL, PLPGSQL</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>C</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
          </div>
        </div>
        {/* BACK OF BACKEND */}
        <div className="experience__frontend">
          <h3>Frontend</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
          </div>
        </div>
        {/* BACK OF FRONTEND */}
        <div className="experience__others">
        <h3>Others</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>VHDL</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>MIPS</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>ARM</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>PSpice</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <BsFillCheckCircleFill className='experience__details-icon'/>
              <div>
                <h4>LTSpice</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default Experience