import React from 'react'
import './contact.css'
import {HiOutlineMail} from 'react-icons/hi'
import {SiFacebook} from 'react-icons/si'
import {GrInstagram} from 'react-icons/gr'

/* for contact form, we first install email.js as a dependency using command 'npm install emailjs-com --save' */
import { useRef } from 'react';
import emailjs, { send } from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_y5hcl51', 'template_7awl67r', form.current, 'RFa4-CV6HAh58mjS-')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id='contact'>
      <h2>Contact Me</h2>
      
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <HiOutlineMail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>lumsunyuan2001@gmail.com</h5>
            <a href="mailto:lumsunyuan2001@gmail.com" target="_blank">Send an email</a>
          </article>
          <article className="contact__option">
            <SiFacebook className="contact__option-icon"/>
            <h4>Messenger</h4>
            <h5>Sun Yuan Lum</h5>
            <a href="https://m.me/sunyuan.lum" target="_blank">Send a message</a>
          </article>
          {/* <article className="contact__option">
            <GrInstagram className="contact__option-icon"/>
            <h4>Instagram</h4>
            <h5>sunyuanlum</h5>
            <a href="https://www.instagram.com/sunyuanlum/" target="_blank">Send a message</a>
          </article> */}
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Name' required />
          <input type="email" name='email' placeholder='Email' required />
          <textarea name="message" rows="6" placeholder='Leave a message here' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact