import React from 'react'
import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsEnvelopeFill} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {FaGraduationCap} from 'react-icons/fa'
import {MdLocationPin} from 'react-icons/md'
import {BsPersonCircle} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Sun Yuan Lum</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        {/* <li><a href="#about">About Me</a></li> */}
        <li><a href="#skills">Skills</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        <li><a href="#projects">Projects</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__information">
        <article className="footer__info">
          <MdLocationPin className='footer__info-icon'/>
          <div>
            <h4>Sydney, NSW, Australia</h4>
          </div>
        </article>
        <article className="footer__info">
          <BsPersonCircle className='footer__info-icon'/>
          <div>
            <h4>Software Engineer</h4>
          </div>
        </article>
        <article className="footer__info">
          <FaGraduationCap className='footer__info-icon'/>
          <div>
            <h4>Bsc of Computer Engineering (Hons)</h4>
          </div>
        </article>
      </div>

      <div className="footer__socials">
        <a href="https://www.facebook.com/sunyuan.lum"><BsFacebook className="footer__socials-icon"/></a>
        <a href="https://www.linkedin.com/in/sun-yuan-lum-048510206/"><BsLinkedin className="footer__socials-icon"/></a>
        <a href="https://www.instagram.com/sunyuanlum/"><BsInstagram className="footer__socials-icon"/></a>
        <a href="mailto:lumsunyuan2001@gmail.com"><BsEnvelopeFill className="footer__socials-icon"/></a>
        <a href="https://github.com/sunyuann"><BsGithub className="footer__socials-icon"/></a>
      </div>

      {/* <div className="footer__copyright">
        <small>&copy; SUN YUAN LUM. All rights reserved.</small>
      </div> */}
    </footer>
  )
}

export default Footer