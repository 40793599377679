import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/sana.jpg'
import IMG2 from '../../assets/sana.jpg'
import IMG3 from '../../assets/sana.jpg'
import IMG4 from '../../assets/sana2.jpg'
import IMG5 from '../../assets/sana2.jpg'
import IMG6 from '../../assets/sana2.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'IoT Data Collection and Sharing Network',
    github: 'https://github.com/sunyuann/IoT-Data-Collection-and-Sharing-Network',
    demo: 'https://github.com/sunyuann/IoT-Data-Collection-and-Sharing-Network'
  },
  {
    id: 2,
    image: IMG2,
    title: 'AI learning to play CartPole game',
    github: 'https://github.com/sunyuann/Cart-Pole-game-with-Reinforcement-Learning',
    demo: 'https://github.com/sunyuann/Cart-Pole-game-with-Reinforcement-Learning'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Social Media Website',
    github: 'https://github.com/sunyuann/Social-media-website',
    demo: 'https://github.com/sunyuann/Social-media-website'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Dungeon Mania Game',
    github: 'https://github.com/sunyuann/Dungeon-Mania',
    demo: 'https://github.com/sunyuann/Dungeon-Mania'
  },
  {
    id: 5,
    image: IMG5,
    title: 'MIPS Simulation',
    github: 'https://github.com/sunyuann/Simple-MIPS',
    demo: 'https://github.com/sunyuann/Simple-MIPS'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Minesweeper',
    github: 'https://github.com/sunyuann/Minesweeper',
    demo: 'https://github.com/sunyuann/Minesweeper'
  },
]

const Portfolio = () => {
  return (
    <section id='projects'>
      {/* <h5>My Recent Work</h5> */}
      <h2>Projects</h2>
      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank'>Github</a>
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio